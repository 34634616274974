
.mainContainer {
    height: 100vh;
    /* display: flex; */
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color:rgb(243, 243, 243) ;   
}
.tableContainer{
    width: 100%;

}
.paginationContainer{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.exportButton{
    display: flex;
    padding-Inline:15px;
    padding-Bottom:0px;
    align-items:'center';
    justify-content: center;
    border-radius: 10px;
    margin-right: 10px;
}