.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* //////////////////////////////////////////////////////////////////// */

.dashBoard_functionalities{
  display: flex;
  flex-direction: row;
}
/* /////////////////////////////////////card/////////////////////////////////////// */

.card_wrapper {
  width: 505px;
  background: #fff;
  border-radius: 15px;
  padding: 30px 30px 25px;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.4);
  text-align:center;
}
.cardsWrapper_Container{
  display: flex;
  flex-wrap: wrap;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background: transparent;
}
/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\profileContainer`````````````````` */
.gutter-box {
  padding: 8px 0;
  background: #00a0e9;
}
[data-theme="dark"] .gutter-box {
  background: #028ac8;
}

.profileWrapperContainer{
  /* display: flex;
  flex-wrap: wrap; */
}
.slot{
  border:'1px solid black';
  background-color:'red'
}