
.mainContainer {
    /* height: 100vh; */
    /* display: flex; */
    /* justify-content: center;
    align-items: center;
    flex-direction: column; */
    background-color: rgb(243, 243, 243);
    /* //grey */
}

.selectedCartonStatusText {
    font-weight: bold;
    font-size: 20px;
    margin-inline-start: 20px;
    margin-top: 0;
    margin-bottom: 0;
}

.paginationContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.exportButton {
    display: flex;
    padding-Inline: 15px;
    padding-Bottom: 0px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-right: 10px;
}

.UpdateStatusButton {
    margin: 15px;
    cursor: 'pointer';
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}